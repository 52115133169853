import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAN_CART,
} from "../actions/cart_actions";

const INITIAL_STATE = {
  products: [],
  count: 0,
};

export function cartReducer(state = INITIAL_STATE, action = {}) {
  const cart = state;
  const product = action.payload;

  switch (action.type) {
    case ADD_TO_CART:
      let exist = findProductIndex(cart.products, product.ID);

      if (exist >= 0) {
        return state;
      } else {
        return {
          products: [...cart.products, product],
          count: cart.count + 1,
        };
      }

    case REMOVE_FROM_CART:
      let ID = action.payload.ID;
      let productIndex = findProductIndex(cart.products, ID);
      let myProducts = cart.products;

      if (productIndex >= 0) {
        myProducts.splice(productIndex, 1);
        return {
          products: myProducts,
          count: cart.count - 1,
        };
      } else {
        return state;
      }

    case CLEAN_CART:
      return INITIAL_STATE;

    default:
      return state;
  }
}

const findProductIndex = (cart, productID) => {
  return cart.findIndex((p) => p.ID === productID);
};
