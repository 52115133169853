import React from "react";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import reducers from "./src/redux/reducers";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { isClient, getCookie } from "./src/utils/app-helpers";
import { INITIAL_STATE } from "./src/redux/reducers/auth_reducer";
import { ModalProvider } from "./src/providers/modalProvider";

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.CONTEXT || process.env.NODE_ENV,
  appVersion: process.env.DEPLOY_ID,
  enabledReleaseStages: ["production"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

export default ({ element }) => {
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const enhancer = composeEnhancers();

  const { loadState, saveState } = require("./src/utils/localStorage");

  let persistedState = loadState();

  /* for logout */
  if (isClient && persistedState !== undefined) {
    let cookie = getCookie("jexp");

    if (cookie === "") {
      persistedState.auth = INITIAL_STATE;
      saveState({
        auth: INITIAL_STATE,
        cart: persistedState.cart,
      });
    }
  }
  // -------------

  const store = createStore(reducers, persistedState, enhancer);

  store.subscribe(() => {
    if (isClient) {
      //this is to get on WP side the cart items number
      document.cookie = `cart_count=${
        store.getState().cart.count
      };domain=.vtutor.com;secure;path=/`;
    }

    saveState({
      cart: store.getState().cart,
      auth: store.getState().auth,
    });
  });

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ModalProvider data={{ login: false }}>{element}</ModalProvider>
      </Provider>
    </ErrorBoundary>
  );
};
