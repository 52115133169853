import React, { createContext, useContext } from "react";

const defaultValue = {};

const LangContext = createContext(defaultValue);

const LangProvider = ({ children, locale }) => {
  return <LangContext.Provider value={locale}>{children}</LangContext.Provider>;
};

const useLang = () => {
  const context = useContext(LangContext);

  if (context === defaultValue) {
    throw new Error("useLang must be used within LangProvider");
  }

  return context;
};

export { useLang, LangProvider };
