export const isCourseInCart = (item, products) => {
  if (products.length > 0) {
    let exist = products.findIndex((p) => p.title === item.title);
    if (exist >= 0) return true;
    else return false;
  } else {
    return false;
  }
};

export function getParameterByName(name, url) {
  if (typeof window !== "undefined") {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

export function encodeQueryData(data) {
  if (
    Boolean(data) &&
    Object.keys(data).length > 0 &&
    data.constructor === Object
  ) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return `?${ret.join("&")}`;
  } else {
    return "";
  }
}

export const formatter = (currency = "USD") => {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });
};

export const format = (formatted) => {
  let [symbol, price] = formatted.split(/([0-9][0-9.]*[0-9])/);

  return `${symbol} ${price}`;
};

// export const pp = (string) => {
//   return parse(DOMPurify.sanitize(string));
// };

export const cloudinaryWatermark = (url) => {
  let base64Url;

  if (typeof window !== "undefined") {
    base64Url = btoa(url);
  } else {
    base64Url = Buffer.from(url).toString("base64");
  }

  return `https://res.cloudinary.com/ie1/image/upload/f_auto,q_auto:best/u_fetch:${base64Url},w_255,h_155,c_fill,q_auto:best,g_south_east,x_-5,y_-5/v1587059505/watermark.png`;
};

export const splitSentence = (text, howManyWords = 30) => {
  let splitedText = text.split(" ");
  let sliced = [];
  let splited = false;

  if (splitedText.length >= howManyWords) {
    sliced = splitedText.slice(0, howManyWords);
    splited = true;
    return { splited, text: sliced.join(" ") + "..." };
  }

  return { splited, text: sliced.join(" ") };
};

export const toSlug = (string) => {
  let result = string
    .toLowerCase()
    .replace("&#38;", "amp")
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
  return result;
};

export const isClient = typeof window !== "undefined";

export const getCookie = (cookiename) => {
  if (isClient) {
    // Get name followed by anything except a semicolon
    var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    return decodeURIComponent(
      !!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
    );
  }
};

export const snakeCase = (string) => {
  return string
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
};

export const wpURL = (locale) => {
  let lang = "";

  if (locale === "es") {
    lang = "/es";
  }

  return `${process.env.GATSBY_WP_URL}${lang}`;
};

export const shuffleArray = (array) => {
  let curId = array.length;
  // There remain elements to shuffle
  while (0 !== curId) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }

  return array;
};

export const objectIsEmpty = (obj) => {
  for (var i in obj) return false;
  return true;
};
