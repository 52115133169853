import "./static/fonts/icons.css";
import "./static/fonts/categories.css";
import "./src/css/style.scss";
// import "typeface-roboto";

import wrapWithProvider from "./wrap-with-provider";
import wrapWithI18nProvider from "./wrap-with-i18n-provider";

// import LogRocket from "logrocket";

// const setupLogRocketReact = require('logrocket-react');

// if (!process.env.GATSBY_DEBUG) {
//     LogRocket.init("8ay6km/vtutor");
//     setupLogRocketReact(LogRocket);
// }

// import { config } from "@fortawesome/fontawesome-svg-core";
// // import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
// config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// if (typeof window !== "undefined") {
//   const WebFont = require("webfontloader");

//   const WebFontConfig = {
//     custom: {
//       families: [
//         "SF UI Display Light",
//         "SF UI Display Medium",
//         "SF UI Display Semibold",
//         "SF UI Display Bold",
//       ],
//       urls: ["/fonts/styleFonts.css"],
//     },
//   };

//   WebFont.load(WebFontConfig);
// }

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithI18nProvider;
