export const ADD_TO_CART = "cart:ADD_TO_CART";
export const REMOVE_FROM_CART = "cart:REMOVE_FROM_CART";
export const CLEAN_CART = "cart:CLEAN_CART";

export function addToCartAction({ ID, image, title, link }) {
  return {
    type: ADD_TO_CART,
    payload: { ID, image, title, link },
  };
}

export function removeFromCartAction({ ID }) {
  return {
    type: REMOVE_FROM_CART,
    payload: { ID },
  };
}

export function cleanCartAction() {
  return {
    type: CLEAN_CART,
  };
}
