import React from "react";
import { I18nProvider } from "@lingui/react";

import catalogEn from "../locales/en/messages.js";
import catalogEs from "../locales/es/messages.js";

const I18n = ({ children, lang }) => {
  const catalog = {
    en: catalogEn,
    es: catalogEs,
  };

  return (
    <I18nProvider language={lang} catalogs={catalog}>
      {children}
    </I18nProvider>
  );
};

export default I18n;
