export const loadState = (itemName = "stateVtutor") => {
  try {
    const serializedState = localStorage.getItem(itemName);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, itemName = "stateVtutor") => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(itemName, serializedState);
  } catch (error) {
    // ignore write errors
    console.error(error);
  }
};
