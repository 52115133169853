export const LOGIN_USER = "auth:LOGIN_USER";
export const LOG_OUT_USER = "auth:LOG_OUT_USER";
export const ADD_COURSE = "auth:ADD_COURSE";

export function loginUserAction({
  email,
  full_name,
  courses,
  coursesCounter,
  avatar_url,
  is_tutor,
  stripe_id
}) {
  return {
    type: LOGIN_USER,
    payload: {
      email,
      full_name,
      courses,
      coursesCounter,
      avatar_url,
      is_tutor,
      stripe_id
    }
  };
}

export function logOutUserAction() {
  return {
    type: LOG_OUT_USER,
    payload: {}
  };
}

export function addCourse(course_id) {
  return {
    type: ADD_COURSE,
    payload: course_id
  };
}
