import React from "react";
import { BreakpointProvider } from "./breakpoint";
import { LangProvider } from "./langProvider";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faStar } from "@fortawesome/pro-solid-svg-icons";
import Layout from "./src/components/i18n";

// library.add(faStar)
// const { lookup, navigatorLanguages } = require("langtag-utils");

// const { loadState } = require("./src/utils/localStorage");

const queries = {
  xs: "(max-width: 320px)",
  sm: "(max-width: 640px)",
  md: "(max-width: 768px)",
  lg: "(max-width: 1024px)",
  xl: "(max-width: 1280px)",
  or: "(orientation: portrait)", // we can check orientation also
};

export default ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  // if (typeof window !== "undefined") {
  //   const browserLang = lookup(
  //     props.pageContext.availableLngs,
  //     navigatorLanguages(),
  //     props.pageContext.fallbackLng
  //   );

  //   const settedLang = loadState("vt_lang");

  //   if (browserLang !== props.pageContext.locale && settedLang === undefined)
  //     window.location.href = props.pageContext.paths[browserLang];

  //   if (settedLang !== undefined && settedLang !== props.pageContext.locale && typeof props.pageContext.paths !== "undefined")
  //     window.location.href = props.pageContext.paths[settedLang];
  // }

  return (
    // <React.StrictMode>
    <BreakpointProvider queries={queries}>
      <Layout {...props} lang={props.pageContext.locale}>
        <LangProvider locale={props.pageContext.locale}>{element}</LangProvider>
      </Layout>
    </BreakpointProvider>
    // </React.StrictMode>
  );
};
