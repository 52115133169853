import React, { useState } from "react";

const ModalContext = React.createContext(null);

const ModalProvider = (props) => {
  const [state, setValues] = useState(props.data);

  const manageModals = (modal) => {
    setValues({
      ...state,
      ...modal,
    });
  };

  return (
    <ModalContext.Provider value={[state, manageModals]}>
      {props.children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
