import { LOGIN_USER, LOG_OUT_USER, ADD_COURSE } from "../actions/auth_actions";

export const INITIAL_STATE = {
  email: null,
  full_name: null,
  courses: [],
  coursesCounter: 0,
  avatar_url: null,
  is_tutor: false,
  stripe_id: null
};

export function authReducer(state = INITIAL_STATE, action = {}) {
  const user = action.payload;

  switch (action.type) {
    case LOGIN_USER:
      return user;
    case LOG_OUT_USER:
      return INITIAL_STATE;
    case ADD_COURSE:
      if (state.email) {
        let newState = { ...state };
        let payload = action.payload.map(Number);

        newState.courses = newState.courses.concat(payload);
        newState.coursesCounter = newState.courses.length;

        return newState;
      } else {
        return state;
      }
    default:
      return state;
  }
}
